import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

type SiteData = {
  siteMetadata: {
    siteTitle: string;
    description: string;
    author: string;
    keywords: string;
    url: string;
  };
};

interface Props {
  pageDescription?: string;
  pageTitle: string;
  keywords?: string;
}

const Seo = ({ pageDescription, pageTitle, keywords }: Props) => {
  const { site }: { site: SiteData } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            description
            author
            keywords
            url
          }
        }
      }
    `
  );

  const metaDescription = pageDescription || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang: "ru-RU",
      }}
      title={pageTitle || site.siteMetadata.siteTitle}
      titleTemplate={`%s | ${site.siteMetadata.siteTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords || site.siteMetadata.keywords,
        },
        {
          property: `og:title`,
          content: site.siteMetadata.siteTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.url,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: site.siteMetadata.url + "/oglogo.png",
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: site.siteMetadata.siteTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    >
      <link rel="preload" href="./mainGroup.svg" />
    </Helmet>
  );
};

export default Seo;
